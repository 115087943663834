import moment from 'moment';
import buyer_dom_manage_autoRechargeLogs from "@/lib/data-service/flight/buyer_dom_manage_autoRechargeLogs";

export default {
    data() {
        return {
            form: {
                operationTimeStart: '',
                operationTimeEnd: '',
                transferNo: '',
                paymentChannel: 4,
                currentPage: 1,
                pageSize: 10,
            },
            pager: {
                total: 0,
                pageSizes: [10, 20, 30],
            },
            list: [],
            operationDate: '',
        };
    },
    activated() {
        this.init();
    },
    methods: {
        //初始化
        async init() {
            this.getList();
        },

        //获取列表
        async getList() {
            this.form.paymentChannel = this.$route.query.paymentChannel;
            let res = await buyer_dom_manage_autoRechargeLogs(this.form);
            this.list = res.pageResult.pageData;
            this.pager.total = res.pageResult.totalCount;
        },

        reset() {
            this.form = {
                operationTimeStart: '',
                operationTimeEnd: '',
                transferNo: '',
                currentPage: 1,
                pageSize: 10,
            };
            this.operationDate = '';
            this.getList();
        },

        //改变操作日期
        changeOperationDate(newVal) {
            this.form.operationTimeStart = moment(newVal[0]).format('YYYY-MM-DD') + ' 00:00:00';
            this.form.operationTimeEnd = moment(newVal[1]).format('YYYY-MM-DD') + ' 23:59:59';
        },

        //换页
        changeCurrentPage(currentPage) {
            this.form.currentPage = currentPage;
            this.getList();
        },

        //改变每页显示最多数据量
        changePageSize(pageSize) {
            this.form.pageSize = pageSize;
            this.changeCurrentPage(1);
        },
    }
};